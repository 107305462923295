import './Pages.css';
import Dropdown from 'react-bootstrap/Dropdown';

function Home(props) {

  return (

    <div className='container mb-4'>
      <section>
        <article>
          <h1>Software Engineer</h1>
          <img
          className="Kimo"
          src="https://i.imgur.com/B5X6HBX.jpg"
          alt="Third slide"
        />
          <h2>My Coding Skills</h2>
        </article>

        <div className='box'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
             Coding Languages
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">CSS</Dropdown.Item>
              <Dropdown.Item href="#/action-2">EJS</Dropdown.Item>
              <Dropdown.Item href="#/action-3">HTML</Dropdown.Item>
              <Dropdown.Item href="#/action-1">Javascript</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Python</Dropdown.Item>
              <Dropdown.Item href="#/action-3">SQL</Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>
        </div> <br />
        <div className='box'>

          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Frameworks and Libraries
            </Dropdown.Toggle><br />

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Bootstrap</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Django</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Express.js</Dropdown.Item>
              <Dropdown.Item href="#/action-4">Mongoose</Dropdown.Item>
              <Dropdown.Item href="#/action-5">React</Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>
        </div><br />

        <div className='box'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Databases
            </Dropdown.Toggle><br />

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">MongoDB</Dropdown.Item>
              <Dropdown.Item href="#/action-2">PostgreSQL</Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>

        </div>
      </section>


    </div>
  );

}

export default Home;


