import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Pages.css';

export default function Projects(props) {
  // const [index, setIndex] = useState(0);
  // create state to hold projects
  const [projects, setProjects] = useState(null);

  //create function to make api call
  const getProjectsData = async () => {

    //make api call and get response
    const response = await fetch("./projects.json");

    // turn response into javascript object
    const data = await response.json();

    // set the projects state to the data
    setProjects(data);
  };
  useEffect(() => { getProjectsData() }, []);

  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };
  const loaded = () => {
    return projects.map((project) => (

      <Card className="container1 mb-4" style={{ width: '24rem' }}>
        <Card.Img variant="top" src={project.image} />
        <Card.Body>
          <Card.Title>{project.name}</Card.Title>
          <Card.Text>
            {project.description}
          </Card.Text>
          <a href={project.git}>
            <Button variant="primary">Github</Button>
          </a>
          <a href={project.live}>
            <button className="button1">Live</button>
          </a>
          <p>Technology Used: {project.technology}</p>
        </Card.Body>
      </Card>
    ))

  }
  return projects ? loaded() : <h1>Loading...</h1>;
}