import { Link } from "react-router-dom";
import "./App.css";

function Header(props) {
    //inline style for the nav tag
    const navStyle = {
        display: "flex",
        justifyContent: "space-around",
        border: "3px solid black",
        padding: "8px",
        width: "100%",
        margin: "auto",
        align: "center",
        
        
    };

    return (
        <header>
            <div className="Sakima mb-4" h1>Sakima's Portfolio Page</div>
            <nav className="Navbar mb-4" style={navStyle}>
                <Link to="/">
                    <div className="home">HOME</div>
                </Link>
                <Link to="/about">
                    <div className="about">ABOUT</div>
                </Link>
                <Link to="/projects">
                    <div>PROJECTS</div>
                </Link>
                <Link to="/contact">
                    <div> CONTACT ME</div>
                </Link>
            </nav>
        </header>
    );
}

export default Header;
