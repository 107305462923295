
import './Pages.css';

const PDF_File_Resume = 'https://drive.google.com/uc?export=download&id=1UW_DrmnvALODtt0jeLgdeD8XYicg7-h2'
function Contact(props) {
  const downloadPdfAtURL = (url) => {
    const fileName = url
    const aTag = document.createElement('a')
    aTag.href=url
    aTag.setAttribute('download', fileName)
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };  
  return (
     

    <div className='container mb-4'>
    <h1>Contact Me</h1>
    <img
          className=""
          // src="https://i.imgur.com/Nlz8L29.png"
          alt=""
        />
    <h3>sakimastringer@gmail.com </h3>
    <button onClick={() => {
      downloadPdfAtURL(PDF_File_Resume)}} 
      className="button">Sakima's Resume</button>
      <h2>Click me ⬆️ please!</h2>
    </div>
    

   
  )}
  
  export default Contact;