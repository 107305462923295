import { useState, useEffect } from "react";
import './Pages.css';
import Card from 'react-bootstrap/Card';

function About(props) {
  // create state to hold about data
  const [about, setAbout] = useState(null);

  // create function to make api call
  const getAboutData = async () => {

    // make api call and get response
    const response = await fetch("./about.json");

    // turn response into javascript object
    const data = await response.json();

    // set the about state to the data
    setAbout(data);
  };

  // make an initial call for the data inside a useEffect, so it only happens once on component load
  useEffect(() => { getAboutData() }, []);

  // define a function that will return the JSX needed once we get the data
  const loaded = () => (
    <div className="container mb-4">
      <h2>{about.name}</h2> 
      <div className="card"></div>
        <div className="auger">
          <img 
            src="https://i.imgur.com/4eh7Lxr.jpg"
            />
          <p className="bio">{about.bio}</p>
          <h8>{about.mantra}</h8>
          
      </div>
    </div>

     /* <h2>{about.name}</h2>
      <h3>{about.email}</h3> */
    //   {/* <p>{about.bio}</p>
    //   {/* <img src="https://i.imgur.com/Ta1gtBw.gif" />
    //   {/* <p>I can't wait to be apart of your project and or team!</p> */}
    //   <div className="wait">
        
    //   </div>
    // </div> */} */}

  );


// function BasicExample() {
//     return (
//       <Card style={{ width: '18rem' }}>
//         <Card.Img variant="top" src="holder.js/100px180" />
//         <Card.Body>
//           <Card.Title>Card Title</Card.Title>
//           <img src="https://i.imgur.com/Ta1gtBw.gif" />
//           <Card.Text>
//           "I can't wait to be apart of your project and or team!"
//           </Card.Text>
//         </Card.Body>
//       </Card>
//     );
//   }
  


  // if data arrives return the result of loaded, if not, an h1 that says loading
  return about ? loaded() : <h1>Loading...</h1>;
}

<div className='Me'>
  <header>

    <p>

    </p>
  </header>
</div>
export default About;
